import { deserializeFormattedDate } from 'src/common/types'
import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  PaginatedItems,
  PaginationResponse,
  Permissions,
  permissionsDeserializer,
} from 'src/store/APIs/types'

export enum Endpoints {
  GetNavigators = '/cms/v1/cms_navigators.json',
  GetNavigator = '/cms/v1/cms_navigators/%s.json',
  GetNavigatorsSelect = 'cms/v1/cms_navigators/select_navigators',
  AddNavigator = '/cms/v1/cms_navigators.json',
  EditNavigator = '/cms/v1/cms_navigators/%s.json',
  DeleteNavigator = '/cms/v1/cms_navigators/%s.json',
}

export interface NavigatorResponse extends Entity {
  id: string
  email: string
  name: string
  last_name: string
  created_at: string
  updated_at: string
}

export interface Navigator extends Entity {
  id: string
  email: string
  name: string
  last_name: string
  created_at: string
  updated_at: string
  full_name: string
}

export interface GetNavigatorsResponse {
  cms_navigators: NavigatorResponse[]
  pagination: PaginationResponse
}

export interface GetNavigatorsOptionsResponse {
  cms_navigators: BaseUserResponse[]
  pagination: PaginationResponse
}

export const navigatorDeserializer = (data: NavigatorResponse): Navigator => {
  return {
    created_at: deserializeFormattedDate(data.created_at),
    email: data.email,
    full_name: `${data.name} ${data.last_name}`,
    id: data.id,
    last_name: data.last_name,
    name: data.name,
    permissions: permissionsDeserializer(data.permissions),
    updated_at: deserializeFormattedDate(data.updated_at),
  }
}

export const navigatorSelectDeserializer = (
  data: BaseUserResponse,
): BaseUser => {
  return {
    full_name: `${data.name} ${data.last_name}`,
    id: data.id,
    user_type: data.user_type,
  }
}

export const navigatorsDeserializer = (
  data: NavigatorResponse[],
): Navigator[] => data.map(navigatorDeserializer)

export const navigatorsOptionsDeserializer = (
  data: BaseUserResponse[],
): BaseUser[] => data.map(baseUserDeserializer)

export const getNavigatorsResponseDeserializer = (
  data: GetNavigatorsResponse,
): PaginatedItems<Navigator> => {
  return {
    items: navigatorsDeserializer(data.cms_navigators),
    pagination: data.pagination,
  }
}

export const getNavigatorResponseSelectDeserializer = (
  data: GetNavigatorsOptionsResponse,
): PaginatedItems<BaseUserResponse> => {
  return {
    items: navigatorsOptionsDeserializer(data.cms_navigators),
    pagination: data.pagination,
  }
}

export interface NavigatorDetailsResponse {
  id: string
  email: string
  name: string
  last_name: string
  created_at: string
  updated_at: string
  full_name: string
  address: string
  biography: string
  gender: string
  image: null | string
  mobile_survivors: BaseUserResponse[]
  permissions: Permissions
  phone_number: string
}

export interface NavigatorDetails extends Entity {
  id: string
  email: string
  name: string
  last_name: string
  full_name: string
  address: string
  biography: string
  gender: string
  image?: string
  mobile_survivors: BaseUser[]
  phone_number: string
}

export const navigatorDetailsDeserializer = (
  data: NavigatorDetailsResponse,
): NavigatorDetails => {
  return {
    address: data.address,
    biography: data.biography,
    email: data.email,
    full_name: `${data.name} ${data.last_name}`,
    gender: data.gender,
    id: data.id,
    image: data.image ?? undefined,
    last_name: data.last_name,
    mobile_survivors: data.mobile_survivors.map(item =>
      baseUserDeserializer(item),
    ),
    name: data.name,
    permissions: permissionsDeserializer(data.permissions),
    phone_number: data.phone_number,
  }
}
