import { Button } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { AdminDetails } from 'src/store/APIs/admins/types'

import { strings } from './strings'
import { AdminsDataIndex } from './types'

export const useAdminsColumns = (onClickRow: (id: string) => () => void) => {
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()
  const { generateColumnSearchProps } = useColumnSearch()

  const columns: ColumnsType<AdminDetails> = [
    {
      ...generateColumnTitle(AdminsDataIndex.full_name, strings.nameLabel),
      ...generateColumnSearchProps(AdminsDataIndex.full_name),
      filteredValue: filteredInfo.filters?.full_name || null,
      render: (title, record) => {
        return record.permissions?.can_read && onClickRow ? (
          <Button type="link" onClick={onClickRow(record.id)}>
            {title}
          </Button>
        ) : (
          title
        )
      },
      sorter: true,
      width: '30%',
    },
    {
      ...generateColumnTitle(AdminsDataIndex.email, strings.emailLabel),
      ...generateColumnSearchProps(AdminsDataIndex.email),
      filteredValue: filteredInfo.filters?.email || null,
      sorter: true,
      width: '30%',
    },
    {
      ...generateColumnTitle(AdminsDataIndex.created_at, strings.createdDate),
      render: deserializeShortFormattedDate,
      sorter: true,
    },
    {
      ...generateColumnTitle(AdminsDataIndex.updated_at, strings.updatedDate),
      render: deserializeShortFormattedDate,
      sorter: true,
    },
  ]
  return {
    filteredInfo,
    ...restFilteredInfo,
    columns,
  }
}
