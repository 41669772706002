import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    emailLabel: 'Email',
    emailPlaceholder: 'example@example.com',
    forgotPasswordTitle: 'Forgot password?',
    modalBody:
      'A verification link has been sent to the email on file. Open your email to tap on that link to reset your password.',
    modalTitle: 'Check your email',
    requiredFieldValidation: 'This field is required',
    submitButtonLabel: 'Submit',
    subtitle: 'Enter the email address associated with the account.',
    validEmailValidation: 'Please enter a valid email address',
  },
})
