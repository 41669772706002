import { Form, Modal } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Logo from 'src/assets/images/stones.svg'
import { Button } from 'src/components/Button'
import { TextInput } from 'src/components/TextInput'
import { Paths } from 'src/routes/routes'
import { useForgotPasswordMutation } from 'src/store/APIs/auth'

import styles from './forgotPassword.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

export const ForgotPassword: React.FC = () => {
  const history = useHistory()
  const [triggerForgotPassword] = useForgotPasswordMutation()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleSubmit = (values: { email: string }) => {
    triggerForgotPassword({
      email: values.email,
    })
      .unwrap()
      .then(() => setIsModalVisible(true))
  }

  const handleModalOk = () => {
    setIsModalVisible(false)
    history.push(Paths.Login)
  }

  const hideButtonStyle = { style: { display: 'none' } }

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <div className={styles.logoContainer}>
          <img src={Logo} alt="Logo" />
        </div>
        <Form onFinish={handleSubmit} name="forgot_password">
          <div className={styles.title}>{strings.forgotPasswordTitle}</div>
          <p className={styles.subtitle}>{strings.subtitle}</p>
          <Form.Item name="email" rules={[yupSync]} required>
            <TextInput
              placeholder={strings.emailPlaceholder}
              label={strings.emailLabel}
            />
          </Form.Item>
          <Form.Item>
            <div className={styles.submitButtonContainer}>
              <Button htmlType="submit" type="primary">
                {strings.submitButtonLabel}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <Modal
        title={strings.modalTitle}
        open={isModalVisible}
        onOk={handleModalOk}
        cancelButtonProps={hideButtonStyle}>
        <p>{strings.modalBody}</p>
      </Modal>
    </div>
  )
}
