import LocalizedStrings from 'react-localization'

export const generalStrings = new LocalizedStrings({
  en: {
    activeSessionRefresh: 'You have an active session, refreshing..',
    adminLabel: 'Administrator',
    cancel: 'Cancel',
    carePartnerNotFound: `This care partner doesn't exist`,
    carePartnersBreadcrumb: 'Care Partners',
    clearFiltersButtonLabel: 'Clear All Filters',
    communityGroupNotFound: `This community group doesn't exist`,
    dashboardLabel: 'Dashboard',
    delete: 'Delete',
    deploymentDateInvalid: 'The date has passed in the S/CP timezone.',
    entityNotFound: `This entity doesn't exist`,
    genericErrorMessage: 'Something went wrong. Please try again later',
    loggedOutOnPurpose: `You're being logged out.`,
    logoutForExpiration: `You're not logged in.`,
    no: 'No',
    optionalLabel: ' (optional)',
    phoneNumberFormatValidation: 'Phone number must be valid',
    requiredFieldValidation: 'This field is required',
    submit: 'Submit',
    successMessage: 'Success!',
    survivorNotFound: `This survivor doesn't exist`,
    survivorsBreadcrumb: 'Survivors',
    yes: 'Yes',
  },
})
