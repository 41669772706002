import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    carePartnersLabel: 'Care-Partners',
    communityGroupsLabel: 'Community Groups',
    configurationLabel: 'Configuration',
    dashboardLabel: 'Dashboard',
    defaultNavigatorTasksLabel: 'Default Navigator Tasks',
    learnLabel: 'Learn',
    logout: 'Logout',
    navBarTitle: 'Kandu Health',
    programUsersLabel: 'Program Users',
    retailProductsLabel: 'Retail Products',
    suicidalityEmails: 'Suicidality Emails',
    survivorProfileLabel: 'Survivor Profile',
    survivorsLabel: 'Survivors',
    webPortalUsers: 'Web Portal Users',
  },
})
