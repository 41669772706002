import { Home } from 'src/pages/Home'
import { Login } from 'src/pages/Login'
import { NotFound } from 'src/pages/NotFound'
import { Paths, RouteName } from 'src/routes/routes'

import { CarePartner } from './pages/CarePartner'
import { CarePartnerAssessmentDetails } from './pages/CarePartnerAssessmentDetails'
import { CarePartnerResetPassword } from './pages/CarePartnerResetPassword'
import { CarePartners } from './pages/CarePartners'
import { CommunityGroup } from './pages/CommunityGroup'
import { CommunityGroupEdit } from './pages/CommunityGroupEdit'
import { CommunityGroups } from './pages/CommunityGroups'
import { DefaultNavigatorTasks } from './pages/DefaultNavigatorTasks'
import { ForgotPassword } from './pages/ForgotPassword'
import { Learn } from './pages/Learn'
import { ResetPassword } from './pages/ResetPassword'
import { RetailProducts } from './pages/RetailProducts'
import { SuicidalityEmails } from './pages/SuicidalityEmails'
import { SurvivorAssessmentDetails } from './pages/SurvivorAssessmentDetails'
import { SurvivorNew } from './pages/SurvivorNew'
import { SurvivorProfile } from './pages/SurvivorProfile'
import { SurvivorResetPassword } from './pages/SurvivorResetPassword'
import { Survivors } from './pages/Survivors'
import { SurvivorView } from './pages/SurvivorView'
import { WebPortalUsers } from './pages/WebPortalUsers'

const routeConfig = [
  {
    component: Login,
    name: RouteName.Login,
  },
  {
    component: ForgotPassword,
    name: RouteName.ForgotPassword,
    path: Paths.ForgotPassword,
  },
  {
    component: ResetPassword,
    name: RouteName.ResetPassword,
    path: Paths.ResetPassword,
  },
  {
    component: Home,
    name: RouteName.Home,
  },
  {
    component: Survivors,
    name: RouteName.Survivors,
  },
  {
    component: SurvivorAssessmentDetails,
    name: RouteName.SurvivorAssessmentDetails,
    path: Paths.SurvivorAssessmentDetails,
  },
  {
    component: SurvivorNew,
    name: RouteName.SurvivorNew,
    path: Paths.SurvivorNew,
  },
  {
    component: SurvivorView,
    name: RouteName.SurvivorView,
    path: Paths.SurvivorView,
  },
  {
    component: SurvivorResetPassword,
    name: RouteName.SurvivorResetPassword,
    path: Paths.SurvivorResetPassword,
  },
  {
    component: CarePartners,
    name: RouteName.CarePartners,
    path: Paths.CarePartners,
  },
  {
    component: CarePartnerAssessmentDetails,
    name: RouteName.CarePartnerAssessmentDetails,
    path: Paths.CarePartnerAssessmentDetails,
  },
  {
    component: CarePartner,
    name: RouteName.CarePartner,
    path: Paths.CarePartner,
  },
  {
    component: CarePartnerResetPassword,
    name: RouteName.CarePartnerResetPassword,
    path: Paths.CarePartnerResetPassword,
  },
  {
    component: CommunityGroupEdit,
    name: RouteName.CommunityGroupEdit,
    path: Paths.CommunityGroupEdit,
  },
  {
    component: CommunityGroupEdit,
    name: RouteName.CommunityGroupNew,
    path: Paths.CommunityGroupNew,
  },
  {
    component: CommunityGroup,
    name: RouteName.CommunityGroup,
    path: Paths.CommunityGroup,
  },
  {
    component: CommunityGroups,
    name: RouteName.CommunityGroups,
    path: Paths.CommunityGroups,
  },
  {
    component: SurvivorProfile,
    name: RouteName.SurvivorProfile,
    path: Paths.SurvivorProfile,
  },
  {
    component: DefaultNavigatorTasks,
    name: RouteName.DefaultNavigatorTasks,
    path: Paths.DefaultNavigatorTasks,
  },
  {
    component: Learn,
    name: RouteName.Learn,
    path: Paths.Learn,
  },
  {
    component: RetailProducts,
    name: RouteName.RetailProducts,
    path: Paths.RetailProducts,
  },
  {
    component: SuicidalityEmails,
    name: RouteName.SuicidalityEmails,
    path: Paths.SuicidalityEmails,
  },
  {
    component: WebPortalUsers,
    name: RouteName.WebPortalUsers,
    path: Paths.WebPortalUsers,
  },
  {
    component: NotFound,
    name: RouteName.NotFound,
    path: Paths.NotFound,
  },
]

export { routeConfig }
