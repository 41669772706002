import { Form } from 'antd'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { PasswordCondition } from 'src/components/PasswordCondition'
import { PasswordInput } from 'src/components/PasswordInput'
import { usePasswordValidation } from 'src/hooks/usePasswordValidation'
import { Paths } from 'src/routes/routes'
import { useResetPasswordMutation } from 'src/store/APIs/auth'
import { ResetPasswordProps } from 'src/store/APIs/auth/types'

import styles from './resetPassword.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

export const ResetPassword: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('reset_password_token')

  const [triggerResetPassword] = useResetPasswordMutation()

  const handleSubmit = async (values: ResetPasswordProps) => {
    triggerResetPassword({
      password: values.password,
      reset_password_token: values.reset_password_token,
    })
      .unwrap()
      .then(() => history.push(Paths.Login))
  }
  const { password, setPassword, validationStatus } = usePasswordValidation()

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <Form onFinish={handleSubmit} name="reset_password">
          <div className={styles.title}>{strings.title}</div>
          <Form.Item name="password" rules={[yupSync]}>
            <PasswordInput
              placeholder={strings.passwordLabel}
              required
              label={strings.passwordLabel}
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
          </Form.Item>
          <Form.Item name="reset_password_token" hidden initialValue={token} />
          <PasswordCondition
            label={strings.firstRequirement}
            isValid={validationStatus.minLength}
          />
          <PasswordCondition
            label={strings.secondRequirement}
            isValid={validationStatus.upperAndLowerCase}
          />
          <PasswordCondition
            label={strings.thirdRequirement}
            isValid={validationStatus.numberAndLetter}
          />
          <Form.Item>
            <div className={styles.submitButtonContainer}>
              <Button htmlType="submit" type="primary">
                {strings.submitButtonLabel}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
