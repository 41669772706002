import { Rule } from 'antd/es/form'
import { phoneValidator, yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from '../strings'

const schema = yup.object({
  address: yup.string().notRequired().nullable(),
  biography: yup.string().required(strings.requiredValidation),
  email: yup
    .string()
    .email(strings.emailValidation)
    .required(strings.requiredValidation),
  last_name: yup.string().required(strings.requiredValidation),
  name: yup.string().required(strings.requiredValidation),
  phone_number: phoneValidator.required(strings.requiredValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
