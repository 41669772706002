import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import { areFiltersOn } from 'src/pages/Learn/types'
import { useMeQuery } from 'src/store/APIs/misc'
import {
  useAddNavigatorMutation,
  useDeleteNavigatorMutation,
  useEditNavigatorMutation,
  useLazyGetNavigatorQuery,
  useLazyGetNavigatorsQuery,
} from 'src/store/APIs/navigators'
import { UserType } from 'src/store/APIs/types'

import styles from '../webPortalUsers.module.scss'
import { AddNavigator } from './AddNavigator'
import { useNavigatorsColumns } from './columns'
import { strings } from './strings'
import { ViewNavigator } from './ViewNavigator'

interface NavigatorsProps {}

export const Navigators: React.FC<NavigatorsProps> = () => {
  const { open, onCloseDrawer, selectedId, openView, showDrawer } = useDrawer()
  const { resetFilters, ...columnsAndFilters } = useNavigatorsColumns(openView)
  const [triggerAddResourceMutation, { isLoading: isLoadingAdd }] =
    useAddNavigatorMutation()
  const [triggerEditResourceMutation, { isLoading: isLoadingEdit }] =
    useEditNavigatorMutation()
  const [triggerDeleteResourceMutation, { isLoading: isLoadingDelete }] =
    useDeleteNavigatorMutation()
  const { data } = useMeQuery()

  const isLoadingRightButton = isLoadingAdd || isLoadingEdit

  const filtersOn = areFiltersOn(columnsAndFilters.filteredInfo.filters)

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.buttonStyle}
          onClick={resetFilters}
          disabled={!filtersOn}>
          {generalStrings.clearFiltersButtonLabel}
        </Button>
        {data?.user.user_type === UserType.admin && (
          <Button
            type="primary"
            className={styles.buttonStyle}
            onClick={showDrawer}>
            {strings.addNavigatorButtonLabel}
          </Button>
        )}
      </div>
      <DrawerWithState
        selectedId={selectedId}
        open={open}
        onClose={onCloseDrawer}
        resourceName={strings.resourceName}
        RenderAdd={AddNavigator}
        RenderView={ViewNavigator}
        useLazyGetResource={useLazyGetNavigatorQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddResourceMutation}
        isLoadingLeftButton={isLoadingDelete}
        deleteModalTitle={strings.deleteModalTitle}
        isLoadingRightButton={isLoadingRightButton}
        triggerEditResourceMutation={triggerEditResourceMutation}
        triggerDeleteResourceMutation={triggerDeleteResourceMutation}
      />
      <PaginatedTable
        className={styles.table}
        useLazyQuery={useLazyGetNavigatorsQuery}
        {...columnsAndFilters}
      />
    </div>
  )
}
