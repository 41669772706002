import { NavigatorForm } from 'src/pages/WebPortalUsers/Navigators/AddNavigator/types'
import {
  BaseUserResponse,
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Endpoints,
  getNavigatorResponseSelectDeserializer,
  getNavigatorsResponseDeserializer,
  Navigator,
  NavigatorDetails,
  navigatorDetailsDeserializer,
} from './types'

const generateNavigatorFormData = (body: NavigatorForm) => {
  const formData = new FormData()
  formData.append('cms_navigator[name]', body.name)
  formData.append('cms_navigator[last_name]', body.last_name)
  formData.append('cms_navigator[email]', body.email)
  formData.append('cms_navigator[phone_number]', body.phone_number)
  formData.append('cms_navigator[biography]', body.biography)

  if (body.address) {
    formData.append('cms_navigator[address]', body.address)
  }

  if (!body.image) {
    formData.append('cms_navigator[image]', '')
  } else if (body.image && typeof body.image !== 'string') {
    formData.append('cms_navigator[image]', body?.image)
  }

  return formData
}

export const getNavigators = (builder: Builder) =>
  builder.query<PaginatedItems<Navigator>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.Navigators),
    query: endpointWithFilters(Endpoints.GetNavigators),
    transformResponse: getNavigatorsResponseDeserializer,
  })

export const getNavigator = (builder: Builder) =>
  builder.query<NavigatorDetails, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Navigators }],
    query: ({ id }) => format(Endpoints.GetNavigator, id),
    transformResponse: ({ cms_navigator }) =>
      navigatorDetailsDeserializer(cms_navigator),
  })

export const getNavigatorOptions = (builder: Builder) =>
  builder.query<PaginatedItems<BaseUserResponse>, ParamsWithFilters>({
    providesTags: () => [{ type: Tags.Navigators }],
    query: endpointWithFilters(Endpoints.GetNavigatorsSelect),
    transformResponse: getNavigatorResponseSelectDeserializer,
  })

export const addNavigator = (builder: Builder) => {
  return builder.mutation<PerformActionResponse, { body: NavigatorForm }>({
    invalidatesTags: () => [{ type: Tags.Navigators }],
    query: ({ body }) => {
      const formData = generateNavigatorFormData(body)
      return {
        body: formData,
        method: HttpMethod.Post,
        url: Endpoints.AddNavigator,
      }
    },
  })
}

export const editNavigator = (builder: Builder) => {
  return builder.mutation<
    PerformActionResponse,
    { id: string; body: NavigatorForm }
  >({
    invalidatesTags: () => [{ type: Tags.Navigators }],
    query: ({ id, body }) => {
      const formData = generateNavigatorFormData(body)
      return {
        body: formData,
        method: HttpMethod.Put,
        url: format(Endpoints.EditNavigator, id),
      }
    },
  })
}

export const deleteNavigator = (builder: Builder) => {
  return builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.Navigators),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteNavigator, id),
    }),
  })
}
