import { ReactNode } from 'react'

export enum WebPortalUsersTabNames {
  Navigators = 'Navigators',
  Admins = 'Admins',
}

export interface WebPortalUsersTab {
  content: ReactNode
  tabName: WebPortalUsersTabNames
}
