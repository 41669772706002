import { LocalStorageKey } from 'src/common/types'
import { baseApi } from 'src/store/APIs'
import {
  PerformActionResponse,
  performActionTransformResponse,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'

import {
  AuthResponse,
  Builder,
  CheckTimeResponse,
  CsrfResponse,
  Endpoints,
  ResetPasswordProps,
  Tags,
} from './types'

interface AuthUser {
  email: string
  password: string
  authenticityToken?: string
}

export const login = (builder: Builder) =>
  builder.mutation<AuthResponse, AuthUser>({
    invalidatesTags: [Tags.Auth],
    query: ({ email, password, authenticityToken }) => ({
      body: {
        authenticity_token: authenticityToken,
        user: { email, password },
      },
      method: HttpMethod.Post,
      url: Endpoints.Login,
    }),
    transformResponse: (data: AuthResponse) => {
      localStorage.setItem(LocalStorageKey.isLoggedIn, 'true')
      return data
    },
  })

export const getCsrfToken = (builder: Builder) =>
  builder.query<CsrfResponse, void>({
    providesTags: [Tags.Auth],
    query: () => Endpoints.GetCsrfToken,
  })

export const logout = (builder: Builder) =>
  builder.mutation<AuthResponse, void>({
    invalidatesTags: [Tags.Auth],
    onQueryStarted(_, api) {
      api.queryFulfilled.finally(() => {
        api.dispatch(baseApi.util.resetApiState())
      })
    },
    query: () => ({
      method: HttpMethod.Delete,
      url: Endpoints.Logout,
    }),
    transformResponse: (data: AuthResponse) => {
      localStorage.removeItem(LocalStorageKey.isLoggedIn)
      return data
    },
  })

export const keepAliveSession = (builder: Builder) =>
  builder.mutation<PerformActionResponse, void>({
    query: () => ({
      method: HttpMethod.Patch,
      url: Endpoints.KeepAliveSession,
    }),
  })

export const checkSessionRemainingTime = (builder: Builder) =>
  builder.query<CheckTimeResponse, void>({
    query: () => Endpoints.CheckSessionRemainingTime,
  })

export const forgotPassword = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { email: string }>({
    query: ({ email }) => ({
      body: { email },
      method: HttpMethod.Post,
      url: Endpoints.ForgotPassword,
    }),
  })

export const resetPassword = (builder: Builder) =>
  builder.mutation<PerformActionResponse, ResetPasswordProps>({
    query: ({ password, reset_password_token }) => ({
      body: { password, reset_password_token },
      method: HttpMethod.Post,
      url: Endpoints.ResetPassword,
    }),
    transformResponse: performActionTransformResponse,
  })
