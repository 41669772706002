import { deserializeFormattedDate } from 'src/common/types'
import {
  Entity,
  PaginatedItems,
  PaginationResponse,
  permissionsDeserializer,
} from 'src/store/APIs/types'

export enum Endpoints {
  GetAdmins = '/cms/v1/cms_admins.json',
  AddAdmin = '/cms/v1/cms_admins.json',
  EditAdmin = '/cms/v1/cms_admins/%s.json',
  GetAdmin = '/cms/v1/cms_admins/%s.json',
  DeleteAdmin = '/cms/v1/cms_admins/%s.json',
}

export interface AdminResponse extends Entity {
  id: string
  email: string
  first_name: string
  last_name: string
  created_at: string
  updated_at: string
  phone_number: string
  image: string | null
  last_sign_in_at: string | null
  sign_in_count: number
}

export interface Admin extends Entity {
  id: string
  email: string
  first_name: string
  last_name: string
  created_at: string
  updated_at: string
  full_name: string
  phone_number: string
  image?: string
}

export interface AdminDetails extends Admin {
  last_sign_in_at?: string
  sign_in_count?: number
}

export interface GetAdminsResponse {
  cms_admins: AdminResponse[]
  pagination: PaginationResponse
}

export const adminDeserializer = (data: AdminResponse): Admin => {
  return {
    ...data,
    created_at: deserializeFormattedDate(data.created_at),
    email: data.email,
    first_name: data.first_name,
    full_name: `${data.first_name} ${data.last_name}`,
    id: data.id,
    image: data.image ?? undefined,
    last_name: data.last_name,
    permissions: permissionsDeserializer(data.permissions),
    phone_number: data.phone_number,
    updated_at: deserializeFormattedDate(data.updated_at),
  }
}

export const adminDetailsDeserializer = (
  data: AdminResponse,
): AdminDetails => ({
  ...adminDeserializer(data),
  last_sign_in_at: data.last_sign_in_at
    ? deserializeFormattedDate(data.last_sign_in_at)
    : undefined,
  sign_in_count: data.sign_in_count,
})

export const adminsDeserializer = (data: AdminResponse[]): Admin[] =>
  data.map(adminDeserializer)

export const getAdminsResponseDeserializer = (
  data: GetAdminsResponse,
): PaginatedItems<Admin> => {
  return {
    items: adminsDeserializer(data.cms_admins),
    pagination: data.pagination,
  }
}
