import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  password: yup.string().required(strings.requiredFieldValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
