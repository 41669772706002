/* eslint-disable @typescript-eslint/naming-convention */
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { message } from 'antd'
import { SorterResult } from 'antd/es/table/interface'
import { RcFile } from 'antd/es/upload'
import { ORDERED_TABS } from 'src/common/constants'
import { generalStrings } from 'src/common/generalStrings'
import {
  CustomDate,
  dateDeserializer,
  DEFAULT_TIMEZONE,
  deserializeBasicFormattedDate,
  Option,
  Status,
  TabTypes,
} from 'src/common/types'
import { AssessmentStatusOptions } from 'src/components/AssessmentsView/types'
import { AudienceOptions, ThemeOptions } from 'src/pages/Learn/Articles/types'
import { TypeOptions } from 'src/pages/SurvivorView/NavigatorTasks/types'
import { goToPage, RouteName } from 'src/routes'
import { ErrorStatus, GeneralTagIds } from 'src/store/helpers'
import { format } from 'util'

import {
  CarePartnerResponse,
  Endpoints as CarePartnerEndpoints,
} from './carePartners/types'
import { Endpoints as CommunicationLogsEndpoints } from './communicationLogs/types'
import { Endpoints as CommunityEndpoints } from './communityGroups/types'
import { Endpoints as MobileUserEndpoints } from './mobileUsers/types'
import { Endpoints as NavigatorEndpoints } from './navigators/types'
import { Endpoints as NavigatorTasksEndpoints } from './navigatorTasks/types'
import {
  Endpoints as SurvivorEndpoints,
  GetSurvivorResponse,
} from './survivors/types'
import { UserRole } from './users/types'

export type Endpoints =
  | SurvivorEndpoints
  | CarePartnerEndpoints
  | CommunityEndpoints
  | NavigatorEndpoints
  | MobileUserEndpoints
  | NavigatorTasksEndpoints
  | CommunicationLogsEndpoints

export enum Tags {
  Hospitals = 'Hospitals',
  Packages = 'Packages',
  RetailProducts = 'RetailProducts',
  PlanOfCare = 'PlanOfCare',
  CommunicationLogs = 'CommunicationLogs',
  Documents = 'Documents',
  Survivors = 'Survivors',
  Conversations = 'Conversations',
  Assessments = 'Assessments',
  QuickJots = 'QuickJots',
  QuestionsToAsk = 'QuestionsToAsk',
  Todos = 'Todos',
  OtherEvents = 'OtherEvents',
  Tacas = 'Tacas',
  NavigatorTasks = 'NavigatorTasks',
  PurchaseTodos = 'PurchaseTodos',
  CarePartners = 'CarePartners',
  CommunityGroups = 'CommunityGroups',
  HealthcareTeamRoles = 'HealthcareTeamRoles',
  HealthcareTeam = 'HealthcareTeam',
  HealthConditions = 'HealthConditions',
  HealthEvents = 'HealthEvents',
  HospitalTreatments = 'HospitalTreatments',
  Impairments = 'Impairments',
  MobileUsers = 'MobileUsers',
  Navigators = 'Navigators',
  StrokeCauses = 'StrokeCauses',
  GroupPosts = 'GroupPosts',
  Articles = 'Articles',
  DefaultNavigatorTasks = 'DefaultNavigatorTasks',
  Users = 'Users',
  AssessmentsQuestions = 'AssessmentsQuestions',
  Alerts = 'Alerts',
  RetailCategories = 'RetailCategories',
  SuicidalityEmails = 'SuicidalityEmails',
  Admins = 'Admins',
}

export enum ReducerPath {
  root = 'baseApi',
}

export type BaseQueryFnType = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>

export type Builder = EndpointBuilder<BaseQueryFnType, Tags, ReducerPath>

export interface Identifiable {
  id?: string
}

export interface Permissions {
  can_create?: boolean
  can_destroy?: boolean
  can_read?: boolean
  can_update?: boolean
  can_create_post?: boolean
  can_create_comment?: boolean
  can_like?: boolean
  can_unassign?: boolean
  can_disable?: boolean
  can_enable?: boolean
  can_remove?: boolean
}

export interface Entity {
  permissions?: Permissions
}

export interface Model {
  model_permissions?: Permissions
}

export interface PaginatedModel extends Model {
  pagination: PaginationResponse
}

export interface PaginatedParams {
  id?: string
  page?: number
}

export interface User {
  id: string
  first_name: string
  last_name: string
}

export interface Hospital {
  id: string
  name: string
}

export interface PaginationResponse {
  count: number // INFO: Total of elements
  from: number
  in: number
  items: number
  last: number
  next: number
  offset: number
  outset: number
  page: number
  pages: number
  prev: number | null
  to: number
}

export enum UserType {
  mobile_survivor = 'mobile_survivor',
  mobile_caregiver = 'mobile_caregiver',
  cms_navigator = 'cms_navigator',
  admin = 'administrator',
}

export interface BaseUserResponse extends Entity {
  id?: string
  first_name?: string
  name?: string
  last_name?: string
  full_name?: string
  email?: string
  hospital?: Hospital
  user_type: UserType
  mobile_user_id?: string
  time_zone?: string // TODO: check if this is necessary
}

export interface BaseUser extends Entity {
  id?: string
  full_name: string
  user_type: UserType
  email?: string
  hospital?: Hospital
  mobile_user_id?: string
  time_zone?: string // TODO: check if this is necessary
}

export interface Body<T> {
  body: T
  id: string
}

// TODO: If we want any specific behavior we can change this instead of every place
export const permissionsDeserializer = (
  data?: Permissions,
): Permissions | undefined => data ?? undefined

export const fullNameDeserializer = (
  data: Partial<BaseUserResponse> | GetSurvivorResponse,
): string => {
  if (
    data &&
    'user_type' in data &&
    data.user_type === UserType.cms_navigator
  ) {
    return data.full_name!
  }
  return `${data?.first_name || (data as BaseUserResponse)?.name || ''} ${
    data?.last_name
  }`
}

export const baseUserDeserializer = (data: BaseUserResponse): BaseUser => ({
  email: data?.email,
  full_name:
    data?.user_type === UserType.admin
      ? generalStrings.adminLabel
      : fullNameDeserializer(data),
  hospital: data?.hospital,
  id: data?.id,
  mobile_user_id: data?.mobile_user_id,
  permissions: data?.permissions
    ? permissionsDeserializer(data?.permissions)
    : undefined,

  time_zone: data?.time_zone,
  // INFO: In some case we display it and in other for creating a AppLink route
  user_type: data?.user_type,
})

export interface PaginatedItems<T> extends Model {
  items: T[]
  pagination: PaginationResponse
}

export const getUserType = (userType: UserType) => {
  switch (userType) {
    case UserType.mobile_survivor:
      return 'Survivor'
    case UserType.mobile_caregiver:
      return 'Care Partner'
    case UserType.cms_navigator:
      return 'Navigator'
    case UserType.admin:
      return generalStrings.adminLabel
    default:
      return userType
  }
}

export const getRouteForUserType = (userType: UserType): RouteName => {
  switch (userType) {
    case UserType.mobile_survivor:
      return RouteName.SurvivorView
    case UserType.mobile_caregiver:
      return RouteName.CarePartner
    case UserType.cms_navigator:
      return RouteName.NotFound
    default:
      return RouteName.NotFound
  }
}

export interface PerformActionResponse {
  message?: string
}

export const performActionTransformResponse = (data: PerformActionResponse) => {
  message.success(data?.message ?? generalStrings.successMessage)
  return data
}

export const performActionGenericSuccess = () => {
  message.success(generalStrings.successMessage)
}

export interface GetUserGroupsResponse extends Model {
  mobile_user_groups: UserGroupsResponse[]
  pagination: PaginationResponse
}

export interface UserGroupsResponse {
  id: string
  group: {
    id: string
    name: string
  }
}

export interface UserGroup {
  id: string
  name: string
}

export const userGroupDeserializer = (data: UserGroupsResponse): UserGroup => ({
  id: data.group.id,
  name: data.group.name,
})

export const userGroupsDeserializer = (
  data: GetUserGroupsResponse,
): PaginatedItems<UserGroup> => ({
  items: data.mobile_user_groups.map(userGroupDeserializer),
  model_permissions: data.model_permissions,
  pagination: data.pagination,
})

// TODO: Probably we should specify who uses this filters
export interface Filters {
  email?: string[]
  phone_number?: string[]
  full_name?: string[]
  status?: Status[] | AssessmentStatusOptions[] | LearnStatus[]
  'status[]'?: Status[] | AssessmentStatusOptions[] | LearnStatus[]
  name?: string[]
  'hospitals.name'?: string[]
  care_partner?: string[]
  alerts?: string[]
  'alerts[]'?: string[]
  title?: string[]
  summary?: string[]
  type_of?: TypeOptions[]
  'type_of[]'?: TypeOptions[]
  assessment?: string[]
  'units.title'?: string[]
  theme?: ThemeOptions[]
  'theme[]'?: ThemeOptions[]
  internal_name?: string[]
  internal_id?: string[]
  audience?: AudienceOptions[]
  'audience[]'?: AudienceOptions[]
  hide?: string[]
  'articles.title'?: string[]
  internal_name_or_id?: string[]
  user_type?: MobileUserType[]
  'roles[]'?: UserRole[]
  roles?: UserRole[]
  'cms_navigators.full_name'?: string[]
  language?: string[]
  type?: string[]
  content_language?: string[]
  item_description?: string[]
  'impairments.name'?: string[]
  'retail_categories.name'?: string[]
  'impairments.name[]'?: string[]
  'retail_categories.name[]'?: string[]
}

export enum MobileUserType {
  Survivor = 'Mobile::Survivor',
  CarePartner = 'Mobile::Caregiver',
}

export enum OrderOption {
  asc = 'asc',
  desc = 'desc',
}

export const sortData = (
  sortInfo: SorterResult<any> | SorterResult<any>[] | undefined,
): string | undefined => {
  if (!sortInfo || Object.entries(sortInfo).length === 0) return undefined

  const sortItem = Array.isArray(sortInfo) ? sortInfo[0] : sortInfo
  const orderType =
    sortItem.order === 'descend' ? OrderOption.desc : OrderOption.asc

  return `order[${sortItem.columnKey}]=${orderType}`
}

export interface ParamsWithFilters extends PaginatedParams {
  filters?: Filters
  sortItem?: string
}

const getQueryParamsForFilters = (filters: ParamsWithFilters) => {
  const queryParams = Object.entries(filters)
    .reduce((acc, [key, values]) => {
      if (Array.isArray(values) && values.length > 0) {
        const filteredValues = values.filter(value => value !== '')
        if (filteredValues.length > 0) {
          const encodedValues = filteredValues
            .map(encodeURIComponent)
            .join(`&${encodeURIComponent(key)}=`)
          return `${acc}&${encodeURIComponent(key)}=${encodedValues}`
        }
      }
      return acc
    }, '')
    .concat(filters.sortItem ? `&${filters.sortItem}` : '')
    .concat(filters.page ? `&page=${filters.page}` : '')

  if (queryParams.length === 0) return ''
  if (queryParams[0] === '&') return `?${queryParams.substring(1)}`
  return `?${queryParams}`
}

export const endpointWithFilters =
  (endpoint: string) =>
  ({ filters = {}, id, page, sortItem }: ParamsWithFilters) => {
    const {
      status,
      alerts,
      type_of,
      audience,
      theme,
      roles,
      ...filtersWithoutArrays
    } = filters

    const impairmentsNames = filters['impairments.name']
    const retailCategoriesNames = filters['retail_categories.name']

    filtersWithoutArrays['status[]'] = status
    filtersWithoutArrays['alerts[]'] = alerts
    filtersWithoutArrays['type_of[]'] = type_of
    filtersWithoutArrays['audience[]'] = audience
    filtersWithoutArrays['theme[]'] = theme
    filtersWithoutArrays['roles[]'] = roles

    if (impairmentsNames) {
      filtersWithoutArrays['impairments.name[]'] = impairmentsNames
    }
    if (retailCategoriesNames) {
      filtersWithoutArrays['retail_categories.name[]'] = retailCategoriesNames
    }
    delete filtersWithoutArrays['impairments.name']
    delete filtersWithoutArrays['retail_categories.name']

    const queryParams = getQueryParamsForFilters({
      ...filtersWithoutArrays,
      page,
      sortItem,
    })

    return `${id ? format(endpoint, id) : endpoint}${queryParams}`
  }

export interface ResetPasswordPayload {
  id: string
  body: {
    new_password: {
      password: string
      password_confirmation: string
    }
  }
}

export const paginatedParams =
  (endpoint: string) =>
  ({ id, page = 1 }: PaginatedParams) =>
    id ? format(endpoint, id, page) : format(endpoint, page)

export interface SelectOption {
  value: string
  label: string
}

export interface PromisGlobalExplanationCategories {
  global_mental_health?: string
  global_physical_health?: string
}

export interface AssessmentScoreResponse {
  final_score: string | null
  promis_global_score: {
    global_physical_health: {
      raw: string
      t_score: string
      se: string
    }
    global_mental_health: {
      raw: string
      t_score: string
      se: string
    }
    general_health_score: {
      raw: string
    }
    social_activities_score: {
      raw: string
    }
  } | null
  explanation?: string | PromisGlobalExplanationCategories
  recomendation?: string | PromisGlobalExplanationCategories
}

export interface GetAssessmentsResponse {
  assign_assessments: AssignAssessmentResponse[]
  pagination: PaginationResponse
  model_permissions: Permissions
}

export interface AssignAssessmentResponse extends Entity {
  id: string
  accomplish_assessment: {
    id: string
    name: string
  }
  answered_date: string | null
  due_time: string
  score: AssessmentScoreResponse | null
  status: AssessmentStatusOptions
  deployment_date: string
  mobile_user_timezone?: string
}

export interface AssessmentsData extends Entity {
  id: string
  assessment: string
  due_time: CustomDate
  score?: AssessmentScoreResponse
  status: AssessmentStatusOptions
  deployment_date: CustomDate
}

export const assessmentDeserializer = (
  data: AssignAssessmentResponse,
): AssessmentsData => {
  return {
    assessment: data.accomplish_assessment.name,
    deployment_date: dateDeserializer(
      data.deployment_date,
      data.mobile_user_timezone ?? DEFAULT_TIMEZONE,
    ),
    due_time: dateDeserializer(data.due_time),
    id: data.id,
    permissions: permissionsDeserializer(data.permissions),
    score: data.score ?? undefined,
    status: data.status,
  }
}

export const assessmentsDeserializer = (
  data: GetAssessmentsResponse,
): PaginatedItems<AssessmentsData> => {
  return {
    items: data.assign_assessments.map(assessmentDeserializer),
    model_permissions: data.model_permissions,
    pagination: data.pagination,
  }
}

export enum AssessmentType {
  QualityOfLifeWork = 'Quality of Life-Work',
  QualityOfLifeSocialRoles = 'Quality of Life-Social Roles',
  ModifiedCaregiverStrainIndex = 'Modified Caregiver Strain Index',
  QualityOfLifeFunction = 'Quality of Life-Function',
  QualityOfLifeEnergy = 'Quality of Life-Energy',
  QualityOfLifeMobility = 'Quality of Life-Mobility',
  PreparednessForCaregiving = 'Preparedness for Caregiving',
  QualityOfLifeMood = 'Quality of Life-Mood',
  QualityOfLifeThinking = 'Quality of Life-Thinking',
  PHQ2_9 = 'PHQ 2/9',
  QualityOfLifeVision = 'Quality of Life-Vision',
  QualityOfLifeFamilyRoles = 'Quality of Life-Family Roles',
  QualityOfLifeLanguage = 'Quality of Life-Language',
  mRs = 'mRs',
  HomeSafetyQuestionnaire = 'Home Safety Questionnaire',
  QualityOfLifePersonality = 'Quality of Life-Personality',
  GAD_7 = 'GAD-7',
  QualityOfLifeSelfCare = 'Quality of Life-Self Care',
  KanduProgramQuestionnaire = 'Kandu Program Questionnaire',
  PreparednessForCaregivingShortVersion = 'Preparedness for Caregiving (short version)',
  PromisGlobal = 'Promis Global',
}

export interface SpokeWithUser {
  first_name: string
  last_name: string
  id: string
  mobile_user_id: string
}

export interface AssessmentDetailResponse {
  id: string
  accomplish_assessment: {
    id: string
    name: AssessmentType
  }
  answered_by: {
    id: string
    first_name: string
    last_name: string
    user_type: UserType
  } | null
  answered_date: string | null
  due_time: string
  responses: {
    answer: string
    description: string
    title: string
  }[]
  score: AssessmentScoreResponse | null
  status: AssessmentStatusOptions
  title: string
  spoke_with_survivor?: SpokeWithUser
  spoke_with_caregiver?: SpokeWithUser
}

export enum PromisGlobalScoreCategories {
  global_physical_health = 'Global Physical Health',
  global_mental_health = 'Global Mental Health',
  general_health_score = 'General Health Score',
  social_activities_score = 'Social Activities Score',
}

export interface PromisGlobalScore {
  category: PromisGlobalScoreCategories
  raw: string
  t_score?: string
  se?: string
  explanation?: string
  recommendation?: string
}

export interface AssessmentScore {
  final_score?: string
  promis_global_score?: PromisGlobalScore[]
  explanation?: string
  recommendation?: string
}

const deserializeAssessmentScore = (
  data: AssessmentScoreResponse,
): AssessmentScore => {
  return {
    explanation:
      typeof data.explanation === 'string' ? data.explanation : undefined,
    final_score: data.final_score ?? undefined,
    promis_global_score: data.promis_global_score
      ? [
          {
            category: PromisGlobalScoreCategories.global_physical_health,
            explanation: (data.explanation as PromisGlobalExplanationCategories)
              .global_physical_health,
            raw: data.promis_global_score.global_physical_health.raw,
            recommendation: (
              data.recomendation as PromisGlobalExplanationCategories
            ).global_physical_health,
            se: data.promis_global_score.global_physical_health.se,
            t_score: data.promis_global_score.global_physical_health.t_score,
          },
          {
            category: PromisGlobalScoreCategories.global_mental_health,
            explanation: (data.explanation as PromisGlobalExplanationCategories)
              .global_mental_health,
            raw: data.promis_global_score.global_mental_health.raw,
            recommendation: (
              data.recomendation as PromisGlobalExplanationCategories
            ).global_mental_health,
            se: data.promis_global_score.global_mental_health.se,
            t_score: data.promis_global_score.global_mental_health.t_score,
          },
          {
            category: PromisGlobalScoreCategories.general_health_score,
            raw: data.promis_global_score.general_health_score.raw,
          },
          {
            category: PromisGlobalScoreCategories.social_activities_score,
            raw: data.promis_global_score.social_activities_score.raw,
          },
        ]
      : undefined,
    recommendation:
      typeof data.recomendation === 'string' ? data.recomendation : undefined,
  }
}

export interface AssessmentDetail {
  id: string
  accomplish_assessment: {
    id: string
    name: AssessmentType
  }
  answered_by?: BaseUser
  answered_date?: string
  due_time: string
  responses: {
    answer: string
    description: string
    title: string
  }[]
  score?: AssessmentScore
  status: AssessmentStatusOptions
  title: string
  spoke_with_survivor?: SpokeWithUser
  spoke_with_caregiver?: SpokeWithUser
}

export const assessmentDetailsDeserializer = (
  data: AssessmentDetailResponse,
): AssessmentDetail => {
  return {
    accomplish_assessment: data.accomplish_assessment,
    answered_by: data.answered_by
      ? baseUserDeserializer(data.answered_by)
      : undefined,
    answered_date: data.answered_date
      ? deserializeBasicFormattedDate(data.answered_date)
      : undefined,
    due_time: deserializeBasicFormattedDate(data.due_time),
    id: data.id,
    responses: data.responses,
    score: data.score ? deserializeAssessmentScore(data.score) : undefined,
    spoke_with_caregiver: data.spoke_with_caregiver,
    spoke_with_survivor: data.spoke_with_survivor,
    status: data.status,
    title: data.title,
  }
}

export const triggerEntityNotFound =
  (rootRoute = RouteName.Home, messageText = generalStrings.entityNotFound) =>
  (error: FetchBaseQueryError) => {
    if (
      error.status === ErrorStatus.NotFound ||
      error.status === ErrorStatus.Forbidden
    ) {
      message.error(messageText)
      goToPage(rootRoute)
    }
  }

export enum ConversationType {
  Private = 'private',
  Group = 'group',
}

export interface ConversationParams extends PaginatedParams {
  type: ConversationType
}

export interface GetConversationResponse extends Model {
  messages: ConversationResponse[]
  pagination: PaginationResponse
}

export interface Conversation extends Entity {
  id: string
  content: string
  sent_at: CustomDate
  sent_by: BaseUserResponse
}

export interface ConversationResponse extends Entity {
  id: string
  content: string
  sent_at: string
  sent_by: BaseUserResponse
}

export const getConversationDeserializer = (
  data: GetConversationResponse,
): PaginatedItems<Conversation> => {
  return {
    items: data.messages.map(
      (item: ConversationResponse): Conversation => ({
        content: item.content,
        id: item.id,
        permissions: item.permissions,
        sent_at: dateDeserializer(item.sent_at),
        sent_by: item.sent_by,
      }),
    ),
    model_permissions: data.model_permissions,
    pagination: data.pagination,
  }
}

export interface ConversationMessage {
  id?: string
  body: {
    conversation_type: ConversationType
    message?: string
    image?: RcFile
  }
}

export interface EditConversationMessage {
  userId: string
  messageId: string
  body: {
    conversation_type: ConversationType
    message: string
  }
}

export interface AddAssessmentValues {
  assessment_id: string
  due_time: CustomDate
  deployment_date: CustomDate
}

export interface AddAssessmentData {
  assessment_id: string
  due_time: string
  deployment_date: string
}

export interface AudioFile {
  id: string
  file: {
    url: string
  }
  created_at: string
  updated_at: string | null
  question_id: string
  record_state: string | null
}

export const provideListToTag = (tag: Tags) => () =>
  [{ id: GeneralTagIds.List, type: tag }]

export enum LearnStatus {
  submitted = 'submitted',
  draft = 'draft',
  disabled = 'disabled',
  unpublished = 'unpublished',
  assigned = 'assigned',
  unassigned = 'unassigned',
  hasAssignedChildren = 'has_assigned_children',
  parentCopy = 'parent_copy',
}

export const tabPermissionDeserializer = (
  data: GetSurvivorResponse | CarePartnerResponse,
) => {
  // INFO: provisional until backend sends tab names uniformly
  const tabPermissions = data?.tabs_permissions?.map((tab: string) =>
    tab === 'learning' ? 'learn' : tab,
  )

  return ORDERED_TABS.filter(
    (tab: TabTypes) =>
      tabPermissions?.includes(tab) &&
      (!(data as GetSurvivorResponse)?.mobile_caregivers ||
        (data as GetSurvivorResponse)?.mobile_caregivers?.length ||
        tab !== TabTypes.GroupConversation),
  )
}

export enum Languages {
  English = 'English',
  Spanish = 'Spanish',
}

export const languageOptions: Option[] = [
  {
    label: Languages.English,
    value: Languages.English,
  },
  {
    label: Languages.Spanish,
    value: Languages.Spanish,
  },
]
