import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  email: yup
    .string()
    .required(strings.requiredFieldValidation)
    .email(strings.validEmailValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
