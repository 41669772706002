import { baseApi } from 'src/store/APIs'

import {
  addAdmin,
  deleteAdmin,
  editAdmin,
  getAdmin,
  getAdmins,
} from './actions'

export const adminsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addAdmin: addAdmin(builder),
    deleteAdmin: deleteAdmin(builder),
    editAdmin: editAdmin(builder),
    getAdmin: getAdmin(builder),
    getAdmins: getAdmins(builder),
  }),
})

export const {
  useDeleteAdminMutation,
  useLazyGetAdminsQuery,
  useAddAdminMutation,
  useEditAdminMutation,
  useLazyGetAdminQuery,
  reducer: adminsApiReducer,
  reducerPath: adminsApiReducerPath,
} = adminsApi
