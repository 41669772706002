import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { Button } from 'src/components/Button'
import { Checkbox } from 'src/components/Checkbox'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import styles from 'src/pages/Learn/learn.module.scss'
import { useLazyGetArticlesQuery } from 'src/store/APIs/articles'

import { useHideChildrenCheckbox } from '../hooks/useHideChildrenCheckbox'
import { PackagesDrawer } from '../Packages/PackagesDrawer'
import { areFiltersOn } from '../types'
import { ArticlesDrawer } from './ArticlesDrawer'
import { useArticlesColumns } from './columns'
import { strings } from './strings'

interface ArticlesProps {}

export const Articles: React.FC<ArticlesProps> = () => {
  const {
    open,
    onCloseDrawer,
    selectedId,
    openView,
    showDrawer,
    setSelectedId,
  } = useDrawer()

  const {
    open: openPackage,
    onCloseDrawer: onClosePackage,
    selectedId: selectedIdPackage,
    openView: openViewPackage,
  } = useDrawer()

  const { hideChildrenFilter, hideChildrenIsChecked, toggleHideChildren } =
    useHideChildrenCheckbox()

  const { resetFilters, ...columnsAndFilters } = useArticlesColumns(
    openView,
    openViewPackage,
  )

  const filtersOn = areFiltersOn(columnsAndFilters.filteredInfo.filters)

  const openPackageDrawer = (id: string) => () => {
    onCloseDrawer()
    openViewPackage(id)()
  }

  return (
    <>
      <div className={styles.buttonContainer}>
        <Checkbox onChange={toggleHideChildren} checked={hideChildrenIsChecked}>
          {strings.hideChildrenLabel}
        </Checkbox>
        <Button
          className={styles.buttonStyle}
          onClick={resetFilters}
          disabled={!filtersOn}>
          {generalStrings.clearFiltersButtonLabel}
        </Button>
        <Button
          type="primary"
          className={styles.buttonStyle}
          onClick={showDrawer}>
          {strings.addArticleButtonLabel}
        </Button>
      </div>
      <ArticlesDrawer
        openAnotherDrawer={openPackageDrawer}
        onCloseDrawer={onCloseDrawer}
        open={open}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
      />
      <PaginatedTable
        useLazyQuery={useLazyGetArticlesQuery}
        {...columnsAndFilters}
        otherFilters={hideChildrenFilter}
      />
      <PackagesDrawer
        onCloseDrawer={onClosePackage}
        open={openPackage}
        selectedId={selectedIdPackage}
      />
    </>
  )
}
