import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    firstRequirement: 'Minimum 8 characters',
    passwordLabel: 'Password',
    requiredFieldValidation: 'This field is required',
    secondRequirement: 'Contain uppercase and lowercase letters',
    submitButtonLabel: 'Submit',
    thirdRequirement: 'Contain numbers and letters',
    title: 'Create New Password',
  },
})
