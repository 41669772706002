import { useState } from 'react'

const passwordRules = {
  minLength: /^(?=.{8,}).*$/,
  numberAndLetter: /^(?=.*\d)(?=.*[a-zA-Z]).*$/,
  upperAndLowerCase: /^(?=.*[a-z])(?=.*[A-Z]).*$/,
}

export interface PasswordValidationStatus {
  minLength: boolean | null
  upperAndLowerCase: boolean | null
  numberAndLetter: boolean | null
}

export const usePasswordValidation = () => {
  const [password, setPassword] = useState('')
  const [validationStatus, setValidationStatus] =
    useState<PasswordValidationStatus>({
      minLength: null,
      numberAndLetter: null,
      upperAndLowerCase: null,
    })

  const validatePassword = (value: string) => {
    setPassword(value)

    if (!value) {
      setValidationStatus({
        minLength: null,
        numberAndLetter: null,
        upperAndLowerCase: null,
      })
      return
    }

    setValidationStatus({
      minLength: passwordRules.minLength.test(value),
      numberAndLetter: passwordRules.numberAndLetter.test(value),
      upperAndLowerCase: passwordRules.upperAndLowerCase.test(value),
    })
  }

  return {
    password,
    setPassword: validatePassword,
    validationStatus,
  }
}
