import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addAdminButtonLabel: 'Add Admin',
    createAccountTitle: 'Create Account',
    createdAtLabel: 'Created At',
    createdDate: 'Created Date',
    deleteModalTitle: 'Are you sure you want to delete this admin?',
    emailLabel: 'Email',
    emailValidation: 'Email must be valid',
    eventLogTitle: 'Event Log',
    firstNameLabel: 'First Name',
    fullNameLabel: 'Full Name',
    lastLoginLabel: 'Last Sign In At',
    lastNameLabel: 'Last Name',
    loginCountLabel: 'Sign In Count',
    nameLabel: 'Name',
    phoneNumberLabel: 'Phone Number',
    profileInformation: 'Profile Information',
    requiredValidation: 'This field is required',
    resourceName: 'Admin',
    updatedDate: 'Updated Date',
  },
})
