import { Row } from 'antd'
import React from 'react'
import {
  deserializeFormattedDate,
  deserializeShortFormattedDate,
} from 'src/common/types'
import { ViewDrawerRenderProps } from 'src/components/DrawerWithState/types'
import { InfoTable } from 'src/components/InfoTable'
import { AdminDetails } from 'src/store/APIs/admins/types'

import { strings } from '../strings'
import styles from './viewAdmin.module.scss'

interface ViewAdminProps extends ViewDrawerRenderProps {
  data?: AdminDetails
}

export const ViewAdmin: React.FC<ViewAdminProps> = ({ data }) => {
  const contentSection = [
    { content: data?.full_name, label: strings.fullNameLabel },
    [
      { content: data?.email, label: strings.emailLabel },
      { content: data?.phone_number, label: strings.phoneNumberLabel },
    ],
  ]

  const contentStyle = {
    width: '60%',
  }

  return (
    <>
      <h3>{strings.profileInformation}</h3>
      <div className={styles.topSection}>
        {contentSection.map(item =>
          Array.isArray(item) ? (
            <Row className={styles.row}>
              {item.map(subItem => (
                <div key={subItem.label}>
                  <div className={styles.title}>{subItem.label}</div>
                  <div className={styles.content}>{subItem.content}</div>
                </div>
              ))}
            </Row>
          ) : (
            <div key={item.label}>
              <div className={styles.title}>{item.label}</div>
              <div className={styles.content}>{item.content}</div>
            </div>
          ),
        )}
      </div>
      <h3>{strings.eventLogTitle}</h3>
      <InfoTable
        contentStyle={contentStyle}
        rows={[
          {
            content: data?.last_sign_in_at
              ? deserializeFormattedDate(data.last_sign_in_at)
              : undefined,
            label: strings.lastLoginLabel,
          },
          {
            content: data?.sign_in_count,
            label: strings.loginCountLabel,
          },
          {
            content: data?.created_at
              ? deserializeShortFormattedDate(data.created_at)
              : undefined,
            label: strings.createdAtLabel,
          },
        ]}
      />
    </>
  )
}
