import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/store/APIs'

import {
  checkSessionRemainingTime,
  forgotPassword,
  getCsrfToken,
  keepAliveSession,
  login,
  logout,
  resetPassword,
} from './actions'
import { Tags } from './types'

export const getCsrfTokenEndpointName = 'getCsrfToken'

export const authApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    checkSessionRemainingTime: checkSessionRemainingTime(builder),
    forgotPassword: forgotPassword(builder),
    [getCsrfTokenEndpointName]: getCsrfToken(builder),
    keepAliveSession: keepAliveSession(builder),
    login: login(builder),
    logout: logout(builder),
    resetPassword: resetPassword(builder),
  }),
  reducerPath: 'authApi',
  tagTypes: [Tags.Auth],
})

export const {
  useLazyCheckSessionRemainingTimeQuery,
  useKeepAliveSessionMutation,
  useGetCsrfTokenQuery,
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  reducer: authApiReducer,
  reducerPath: authApiReducerPath,
} = authApi
