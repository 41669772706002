import { UserOutlined } from '@ant-design/icons'
import { Avatar, Image } from 'antd'
import React from 'react'
import { ViewDrawerRenderProps } from 'src/components/DrawerWithState/types'
import { AppLink, RouteName } from 'src/routes'
import { NavigatorDetails } from 'src/store/APIs/navigators/types'

import { strings } from '../strings'
import styles from './viewNavigator.module.scss'

interface ViewNavigatorProps extends ViewDrawerRenderProps {
  data?: NavigatorDetails
}

export const ViewNavigator: React.FC<ViewNavigatorProps> = ({ data }) => {
  const contentSection = [
    { content: data?.full_name, label: strings.fullNameLabel },
    { content: data?.email, label: strings.emailLabel },
    { content: data?.phone_number, label: strings.phoneNumberLabel },
    {
      content: data?.address || strings.noInformationLabel,
      label: strings.addressTitle,
    },
  ]
  return (
    <>
      <h3>{strings.profileInformation}</h3>
      <div className={styles.topSection}>
        {data?.image ? (
          <Image
            width={200}
            height={200}
            src={data?.image}
            className={styles.image}
            crossOrigin="use-credentials"
          />
        ) : (
          <Avatar size={200} icon={<UserOutlined />} />
        )}
        <div className={styles.contentSection}>
          {contentSection.map(item => (
            <div key={item.label}>
              <div className={styles.title}>{item.label}</div>
              <div className={styles.content}>{item.content}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.title}>{strings.biographyLabel}</div>
      <div className={styles.content}>{data?.biography}</div>
      <h3>{strings.survivorsTitle}</h3>
      <ul className={styles.survivorsList}>
        {data?.mobile_survivors?.length ? (
          data?.mobile_survivors.map(survivor => (
            <li key={survivor.id} className={styles.listItem}>
              <AppLink
                routeName={RouteName.SurvivorView}
                pathParams={{ id: survivor.id! }}>
                {survivor.full_name}
              </AppLink>
            </li>
          ))
        ) : (
          <li>{strings.noSurvivors}</li>
        )}
      </ul>
    </>
  )
}
