import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import { areFiltersOn } from 'src/pages/Learn/types'
import {
  useAddAdminMutation,
  useDeleteAdminMutation,
  useEditAdminMutation,
  useLazyGetAdminQuery,
  useLazyGetAdminsQuery,
} from 'src/store/APIs/admins'
import { useMeQuery } from 'src/store/APIs/misc'
import { UserType } from 'src/store/APIs/types'

import styles from '../webPortalUsers.module.scss'
import { AddAdmin } from './AddAdmin'
import { useAdminsColumns } from './columns'
import { strings } from './strings'
import { ViewAdmin } from './ViewAdmin'

interface AdminsProps {}

export const Admins: React.FC<AdminsProps> = () => {
  const { open, onCloseDrawer, selectedId, showDrawer, openView } = useDrawer()

  const [triggerAddResourceMutation, { isLoading: isLoadingAdd }] =
    useAddAdminMutation()
  const [triggerEditResourceMutation, { isLoading: isLoadingEdit }] =
    useEditAdminMutation()
  const [triggerDeleteResourceMutation, { isLoading: isLoadingDelete }] =
    useDeleteAdminMutation()

  const { resetFilters, ...columnsAndFilters } = useAdminsColumns(openView)

  const isLoadingRightButton = isLoadingAdd || isLoadingEdit

  const { data } = useMeQuery()

  const filtersOn = areFiltersOn(columnsAndFilters.filteredInfo.filters)

  return (
    <>
      <DrawerWithState
        selectedId={selectedId}
        open={open}
        onClose={onCloseDrawer}
        resourceName={strings.resourceName}
        RenderAdd={AddAdmin}
        RenderView={ViewAdmin}
        useLazyGetResource={useLazyGetAdminQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddResourceMutation}
        triggerEditResourceMutation={triggerEditResourceMutation}
        isLoadingRightButton={isLoadingRightButton}
        triggerDeleteResourceMutation={triggerDeleteResourceMutation}
        isLoadingLeftButton={isLoadingDelete}
        deleteModalTitle={strings.deleteModalTitle}
      />
      <div className={styles.container}>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.buttonStyle}
            onClick={resetFilters}
            disabled={!filtersOn}>
            {generalStrings.clearFiltersButtonLabel}
          </Button>
          {data?.user.user_type === UserType.admin && (
            <Button
              type="primary"
              className={styles.buttonStyle}
              onClick={showDrawer}>
              {strings.addAdminButtonLabel}
            </Button>
          )}
        </div>
        <PaginatedTable
          className={styles.table}
          useLazyQuery={useLazyGetAdminsQuery}
          {...columnsAndFilters}
        />
      </div>
    </>
  )
}
