import { baseApi } from 'src/store/APIs'

import {
  addNavigator,
  deleteNavigator,
  editNavigator,
  getNavigator,
  getNavigatorOptions,
  getNavigators,
} from './actions'

export const navigatorsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addNavigator: addNavigator(builder),
    deleteNavigator: deleteNavigator(builder),
    editNavigator: editNavigator(builder),
    getNavigator: getNavigator(builder),
    getNavigatorOptions: getNavigatorOptions(builder),
    getNavigators: getNavigators(builder),
  }),
})

export const {
  useLazyGetNavigatorsQuery,
  useLazyGetNavigatorOptionsQuery,
  useGetNavigatorQuery,
  useLazyGetNavigatorQuery,
  useAddNavigatorMutation,
  useEditNavigatorMutation,
  useDeleteNavigatorMutation,
  reducer: navigatorsApiReducer,
  reducerPath: navigatorsApiReducerPath,
} = navigatorsApi
