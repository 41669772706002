import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SubHeader } from 'src/components/SubHeader'
import { Tabs } from 'src/components/Tabs'
import { RouteName } from 'src/routes'

import { tabBarStyle } from '../Learn/styles'
import { Admins } from './Admins'
import { Navigators } from './Navigators'
import { strings } from './strings'
import { WebPortalUsersTab, WebPortalUsersTabNames } from './types'
import styles from './webPortalUsers.module.scss'

interface WebPortalUsersProps {}

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: strings.title, path: RouteName.WebPortalUsers },
]

export const WebPortalUsers: React.FC<WebPortalUsersProps> = () => {
  const items: WebPortalUsersTab[] = [
    {
      content: <Navigators />,
      tabName: WebPortalUsersTabNames.Navigators,
    },
    {
      content: <Admins />,
      tabName: WebPortalUsersTabNames.Admins,
    },
  ]

  return (
    <div className={styles.container}>
      <SubHeader
        routes={mockedBreadcrumbRoutes}
        title={strings.title}
        className={styles.subHeader}
      />
      <Tabs
        className={styles.tabInfoContainer}
        defaultActiveKey={WebPortalUsersTabNames.Navigators}
        tabBarStyle={tabBarStyle}
        items={items.map(item => {
          const key = item.tabName
          return {
            children: (
              <div className={styles.tabInfoContainer}>{item.content}</div>
            ),
            key,
            label: <div>{item.tabName}</div>,
          }
        })}
      />
    </div>
  )
}
