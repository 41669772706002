import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addPackageButton: 'Add Package',
    audienceLabel: 'Audience',
    createdDate: 'Created Date',
    deletePackageModalTitle: 'Are you sure you want to delete this package?',
    disablePackageModalTitle: 'Are you sure you want to disable this package?',
    hideChildrenLabel: 'Hide children',
    internalIdLabel: 'Internal ID',
    internalNameLabel: 'Internal Name',
    packageLabel: 'Package',
    resourceName: 'Package',
    statusLabel: 'Status',
    titleLabel: 'Title',
    unassignLabel: 'Unassign',
    unassignModalTitle: 'Are you sure you want to unassign this package?',
  },
})
