import { Form, InputProps } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import React from 'react'
import { TextInput } from 'src/components/TextInput'
import { usePhone } from 'src/hooks/usePhone'
import { Admin } from 'src/store/APIs/admins/types'

import { strings } from '../strings'
import styles from './addAdmin.module.scss'
import { yupSync } from './validations'

interface AddAdminProps {
  data?: Admin
}

export const AddAdmin: React.FC<AddAdminProps> = ({ data }) => {
  const form = useFormInstance()

  const { updatePhone } = usePhone(data?.phone_number, form, 'phone_number')

  const onChangePhone: InputProps['onChange'] = event => {
    updatePhone(event.target.value)
  }

  return (
    <div>
      <h3>{strings.profileInformation}</h3>
      <div className={styles.topSection}>
        <div className={styles.inputContainer}>
          <div className={styles.fullNameSection}>
            <Form.Item
              name="first_name"
              rules={[yupSync]}
              className={styles.nameSection}>
              <TextInput
                label={strings.firstNameLabel}
                required
                placeholder={strings.firstNameLabel}
              />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[yupSync]}
              className={styles.nameSection}>
              <TextInput
                label={strings.lastNameLabel}
                required
                placeholder={strings.lastNameLabel}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="phone_number"
            rules={[yupSync]}
            className={styles.phoneSection}>
            <TextInput
              label={strings.phoneNumberLabel}
              required
              placeholder={strings.phoneNumberLabel}
              maxLength={14}
              onChange={onChangePhone}
            />
          </Form.Item>
        </div>
      </div>
      <h3>{strings.createAccountTitle}</h3>
      <Form.Item name="email" rules={[yupSync]}>
        <TextInput
          label={strings.emailLabel}
          required
          placeholder={strings.emailLabel}
        />
      </Form.Item>
    </div>
  )
}
