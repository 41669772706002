import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addArticleButtonLabel: 'Add Article',
    audienceLabel: 'Audience',
    createdDate: 'Created Date',
    deleteArticleModalTitle: 'Are you sure you want to delete this article?',
    disableArticleModalTitle: 'Are you sure you want to disable this article?',
    hideChildrenLabel: 'Hide children',
    internalIdLabel: 'Internal ID',
    internalNameLabel: 'Internal Name',
    packageLabel: 'Package',
    removeLabel: 'Remove',
    removeModalTitle: 'Are you sure you want to remove this article?',
    resourceName: 'Article',
    statusLabel: 'Status',
    themeLabel: 'Theme',
    titleLabel: 'Title',
    unassignLabel: 'Unassign',
    unassignModalTitle: 'Are you sure you want to unassign this article?',
  },
})
