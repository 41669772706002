import {
  DeleteOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from '@ant-design/icons'
import { Form, Image, InputProps, Modal, Upload, UploadFile } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { RcFile } from 'antd/es/upload'
import ImgCrop from 'antd-img-crop'
import React, { useEffect, useState } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { Button } from 'src/components/Button'
import { MultilineTextInput } from 'src/components/MultilineTextInput'
import { TextInput } from 'src/components/TextInput'
import { usePhone } from 'src/hooks/usePhone'
import { NavigatorDetails } from 'src/store/APIs/navigators/types'

import { strings } from '../strings'
import styles from './addNavigator.module.scss'
import { yupSync } from './validations'

interface AddNavigatorProps {
  data?: NavigatorDetails
}

export const AddNavigator: React.FC<AddNavigatorProps> = ({ data }) => {
  const form = useFormInstance()

  const uploadButton = (
    <Button className={styles.uploadButton}>
      <PlusOutlined />
      <div className={styles.uploadButtonLabel}>{strings.uploadImage}</div>
    </Button>
  )

  const [fileList, setFileList] = useState<UploadFile[]>(
    data?.image
      ? [
          {
            name: strings.navigatorAltImageLabel,
            uid: data.id,
            url: data.image,
          },
        ]
      : [],
  )
  const [previewImage, setPreviewImage] = useState('')

  const handleChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[]
  }) => {
    setFileList(newFileList)
    if (newFileList.length === 0) {
      setPreviewImage('')
      form.setFieldValue('image', undefined)
    }
  }

  const beforeUpload = (file: RcFile) => {
    form.setFieldValue('image', file)
    const imageUrl = URL.createObjectURL(file)
    setPreviewImage(imageUrl)
  }

  const handleDelete = () => {
    Modal.confirm({
      cancelText: generalStrings.no,
      icon: <ExclamationCircleFilled />,
      okText: generalStrings.yes,
      okType: 'danger',
      onCancel() {},
      onOk() {
        setFileList([])
        setPreviewImage('')
        form.setFieldValue('image', undefined)
      },
      title: strings.deleteImageConfirmation,
    })
  }

  const { updatePhone } = usePhone(data?.phone_number, form, 'phone_number')

  const onChangePhone: InputProps['onChange'] = event => {
    updatePhone(event.target.value)
  }

  useEffect(() => {
    if (data?.image) {
      setPreviewImage(data.image)
    }
  }, [data])

  return (
    <div>
      <h3>{strings.profileInformation}</h3>
      <div className={styles.topSection}>
        <div>
          <Form.Item name="image">
            <ImgCrop rotationSlider cropShape="round" showGrid aspect={1}>
              <Upload
                fileList={fileList}
                onChange={handleChange}
                listType="picture-circle"
                className={`avatar-uploader ${styles.imageUpload}`}
                maxCount={1}
                accept="image/*"
                beforeUpload={beforeUpload}
                showUploadList={false}>
                {fileList.length === 0 && uploadButton}
              </Upload>
            </ImgCrop>
          </Form.Item>
          {previewImage && (
            <>
              <Image
                src={previewImage}
                width={160}
                height={160}
                className={styles.previewImage}
                wrapperStyle={{ marginTop: -55 }}
              />
              <Button
                className={styles.deleteButton}
                type="primary"
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={handleDelete}
              />
            </>
          )}
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fullNameSection}>
            <Form.Item
              name="name"
              rules={[yupSync]}
              className={styles.nameSection}>
              <TextInput
                label={strings.firstNameLabel}
                required
                placeholder={strings.firstNameLabel}
              />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[yupSync]}
              className={styles.nameSection}>
              <TextInput
                label={strings.lastNameLabel}
                required
                placeholder={strings.lastNameLabel}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="phone_number"
            rules={[yupSync]}
            className={styles.phoneSection}>
            <TextInput
              label={strings.phoneNumberLabel}
              required
              placeholder={strings.phoneNumberLabel}
              maxLength={14}
              onChange={onChangePhone}
            />
          </Form.Item>
        </div>
      </div>
      <Form.Item name="address" rules={[yupSync]}>
        <TextInput
          label={strings.addressTitle}
          placeholder={strings.addressTitle}
        />
      </Form.Item>
      <Form.Item name="biography" rules={[yupSync]}>
        <MultilineTextInput
          label={strings.biographyLabel}
          required
          placeholder={strings.biographyLabel}
        />
      </Form.Item>
      <h3>{strings.createAccountTitle}</h3>
      <Form.Item name="email" rules={[yupSync]}>
        <TextInput
          label={strings.emailLabel}
          required
          placeholder={strings.emailLabel}
        />
      </Form.Item>
    </div>
  )
}
