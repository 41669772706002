import { CheckOutlined, CloseOutlined, MinusOutlined } from '@ant-design/icons'
import React from 'react'

import styles from './passwordCondition.module.scss'

interface PasswordConditionProps {
  label: string
  isValid: boolean | null
}

export const PasswordCondition: React.FC<PasswordConditionProps> = ({
  label,
  isValid,
}) => {
  const redIconStyle = { color: 'red', marginRight: 8 }
  const greenIconStyle = { color: 'green', marginRight: 8 }
  const neutralIconStyle = { color: 'gray', marginRight: 8 }

  const getIcon = () => {
    if (isValid === null) {
      return <MinusOutlined style={neutralIconStyle} />
    }
    return isValid ? (
      <CheckOutlined style={greenIconStyle} />
    ) : (
      <CloseOutlined style={redIconStyle} />
    )
  }

  return (
    <div className={styles.condition}>
      {getIcon()}
      <span>{label}</span>
    </div>
  )
}
