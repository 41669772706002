import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Admin,
  adminDeserializer,
  AdminDetails,
  Endpoints,
  getAdminsResponseDeserializer,
} from './types'

export const getAdmins = (builder: Builder) =>
  builder.query<PaginatedItems<Admin>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.Admins),
    query: endpointWithFilters(Endpoints.GetAdmins),
    transformResponse: getAdminsResponseDeserializer,
  })

export const addAdmin = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: Admin }>({
    invalidatesTags: provideListToTag(Tags.Admins),
    query: ({ body }) => {
      return {
        body: { cms_admin: body },
        method: HttpMethod.Post,
        url: Endpoints.AddAdmin,
      }
    },
    transformResponse: performActionTransformResponse,
  })

export const editAdmin = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string; body: Admin }>({
    invalidatesTags: () => [{ type: Tags.Admins }],
    query: ({ id, body }) => {
      return {
        body: { cms_admin: body },
        method: HttpMethod.Put,
        url: format(Endpoints.EditAdmin, id),
      }
    },
    transformResponse: performActionTransformResponse,
  })

export const getAdmin = (builder: Builder) =>
  builder.query<AdminDetails, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Admins }],
    query: ({ id }) => format(Endpoints.GetAdmin, id),
    transformResponse: ({ user }) => adminDeserializer(user),
  })

export const deleteAdmin = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.Admins),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteAdmin, id),
    }),
    transformResponse: performActionTransformResponse,
  })
