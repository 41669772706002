import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { Button } from 'src/components/Button'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { Navigator } from 'src/store/APIs/navigators/types'

import styles from '../webPortalUsers.module.scss'
import { strings } from './strings'
import { NavigatorsDataIndex } from './types'

export const useNavigatorsColumns = (
  onClickRow: (id: string) => () => void,
) => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()

  const columns: ColumnsType<Navigator> = [
    {
      ...generateColumnTitle(NavigatorsDataIndex.full_name, strings.name),
      ...generateColumnSearchProps(NavigatorsDataIndex.full_name),
      filteredValue: filteredInfo.filters?.full_name || null,
      render: (title, record) => {
        return record.permissions?.can_read && onClickRow ? (
          <Button
            type="link"
            onClick={onClickRow(record.id)}
            className={styles.cell}>
            {title}
          </Button>
        ) : (
          title
        )
      },
      sorter: true,
      width: '30%',
    },
    {
      ...generateColumnTitle(NavigatorsDataIndex.email, strings.emailLabel),
      ...generateColumnSearchProps(NavigatorsDataIndex.email),
      filteredValue: filteredInfo.filters?.email || null,
      sorter: true,
      width: '30%',
    },
    {
      ...generateColumnTitle(
        NavigatorsDataIndex.created_at,
        strings.createdDate,
      ),
      render: deserializeShortFormattedDate,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        NavigatorsDataIndex.updated_at,
        strings.updatedDate,
      ),
      render: deserializeShortFormattedDate,
      sorter: true,
    },
  ]

  return {
    filteredInfo,
    ...restFilteredInfo,
    columns,
  }
}
