import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addNavigatorButtonLabel: 'Add Navigator',
    addressTitle: 'Address',
    biographyLabel: 'Biography',
    cityLabel: 'City',
    createAccountTitle: 'Create Account',
    createdDate: 'Created Date',
    deleteImageConfirmation: 'Are you sure you want to delete this image?',
    deleteModalTitle: 'Are you sure you want to delete this navigator?',
    emailLabel: 'Email',
    emailValidation: 'Email must be valid',
    firstNameLabel: 'First Name',
    fullNameLabel: 'Full Name',
    imagePlaceholder: 'Navigator image',
    lastNameLabel: 'Last Name',
    name: 'Name',
    navigatorAltImageLabel: `Navigator's image`,
    noInformationLabel: 'No information available',
    noSurvivors: 'No survivors assigned',
    phoneNumberLabel: 'Phone Number',
    profileInformation: 'Profile Information',
    requiredValidation: 'This field is required',
    resourceName: 'Navigator',
    stateLabel: 'State',
    streetAddressLabel: 'Street Address',
    survivorsTitle: 'Survivors',
    title: 'Navigators',
    updatedDate: 'Updated Date',
    uploadImage: 'Upload',
    zipCodeLabel: 'Zip Code',
  },
})
