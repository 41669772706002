import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Logo from 'src/assets/images/stones.svg'
import { Button } from 'src/components/Button'
import { Checkbox } from 'src/components/Checkbox'
import { PasswordInput } from 'src/components/PasswordInput'
import { AppLink } from 'src/routes'
import { Paths, RouteName } from 'src/routes/routes'
import { useLoginMutation } from 'src/store/APIs/auth'

import styles from './login.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

const Login = () => {
  const history = useHistory()
  const [loginHandler] = useLoginMutation()

  const handleSubmit = (values: {
    checkbox: boolean
    email: string
    password: string
  }) => {
    loginHandler({
      email: values.email,
      password: values.password,
    })
      .unwrap()
      .then(() => history.push(Paths.Home))
  }

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <div className={styles.logoContainer}>
          <img src={Logo} alt="Logo" />
        </div>
        <Form
          onFinish={handleSubmit}
          name="normal_login"
          initialValues={{ remember: true }}>
          <Form.Item name="email" rules={[yupSync]}>
            <Input
              prefix={<UserOutlined />}
              placeholder={strings.usernamePlaceholder}
            />
          </Form.Item>
          <Form.Item name="password" rules={[yupSync]}>
            <PasswordInput
              prefix={<LockOutlined />}
              placeholder={strings.passwordPlaceholder}
            />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>{strings.rememberMeLabel}</Checkbox>
          </Form.Item>
          <AppLink routeName={RouteName.ForgotPassword}>
            {strings.forgotPasswordLabel}
          </AppLink>
          <Form.Item>
            <div className={styles.submitButtonContainer}>
              <Button htmlType="submit" type="primary">
                {strings.loginLabel}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export { Login }
