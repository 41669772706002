import { Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { generateColumnTitle } from 'src/helpers/utils'
import {
  PageInfo,
  RetailProductSuggestedTodo,
  SuggestedQuestion,
  SuggestedTodo,
} from 'src/store/APIs/articles/types'

import { strings } from './strings'
import {
  ContentDataIndex,
  SuggestedQuestionDataIndex,
  SuggestedTodoDataIndex,
} from './types'
import styles from './viewArticle.module.scss'

export const suggestedQuestionsColumns = (
  isChild: boolean,
): ColumnsType<SuggestedQuestion> => {
  const columns: ColumnsType<SuggestedQuestion> = [
    {
      ...generateColumnTitle(
        SuggestedQuestionDataIndex.question,
        strings.questionLabel,
      ),
    },
  ]

  if (isChild) {
    columns.push({
      ...generateColumnTitle(
        SuggestedQuestionDataIndex.selected,
        strings.selectedLabel,
      ),
      align: 'center',
      render(value: boolean) {
        return value ? (
          <Tag color="green">{strings.tagYes}</Tag>
        ) : (
          <Tag color="default">{strings.tagNo}</Tag>
        )
      },
      width: '1%',
    })
  }

  return columns
}

export const suggestedTodosColumns = (
  isChild: boolean,
): ColumnsType<SuggestedTodo> => {
  const columns: ColumnsType<SuggestedTodo> = [
    {
      ...generateColumnTitle(SuggestedTodoDataIndex.todo, strings.todoLabel),
      width: '50%',
    },
    {
      ...generateColumnTitle(
        SuggestedTodoDataIndex.retail_products,
        strings.retailProductLabel,
      ),
      render(value: RetailProductSuggestedTodo[]) {
        const products = value.map(product => product.name)
        return products.join(', ')
      },
    },
  ]

  if (isChild) {
    columns.push({
      ...generateColumnTitle(
        SuggestedTodoDataIndex.selected,
        strings.selectedLabel,
      ),
      render(value: boolean) {
        return value ? (
          <Tag color="green">{strings.tagYes}</Tag>
        ) : (
          <Tag color="default">{strings.tagNo}</Tag>
        )
      },
      width: '1%',
    })
  }

  return columns
}

export const contentColumns: ColumnsType<string> = [
  {
    ...generateColumnTitle(ContentDataIndex.content, strings.contentLabel),
    render(value) {
      return (
        /* eslint-disable react/no-danger */
        <div dangerouslySetInnerHTML={{ __html: value }} />
      )
    },
  },
]

export const contentWithSsmlColumns: ColumnsType<PageInfo> = [
  {
    ...generateColumnTitle(ContentDataIndex.content, strings.contentLabel),
    render(_, record) {
      return (
        /* eslint-disable react/no-danger */
        record.content ? (
          <div
            className={styles.contentColumn}
            dangerouslySetInnerHTML={{
              __html: record.content,
            }}
          />
        ) : undefined
      )
    },
  },
  {
    ...generateColumnTitle(ContentDataIndex.ssml_content, strings.ssmlLabel),
    render(_, record) {
      return record.ssml_content ? (
        <div className={styles.contentColumn}>{record.ssml_content}</div>
      ) : undefined
    },
  },
]
